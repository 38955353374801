import { useState } from "react";
import * as xslx from 'xlsx'
import "../Styles/hero.css"


export const HeroSection = () => {
    const [errorInput, setErrorInput] = useState('');
    const [btnText, setBtnText] = useState('Descargar')
    const [btnColor, setBtnColor] = useState('btn-primary')
    const [tempData, setTempData] = useState('');
    let errorOutput = [];
    let cleanOutput = [];
    let indexArray = [];
    let file = '';

    const handleFile = (e) => {
        file = e.target.files[0]
        readExcel(file)  
    }

    const handleChange = (e) => {
        if(e.target.value[e.target.value.length - 1] === ',' || e.target.value[e.target.value.length - 1] === ' ' ){
          setBtnText('Elimina ultima coma o espacio')
          setBtnColor('btn-danger disabled')
        } else {
          setBtnText('Descargar')
          setBtnColor('btn-primary')
        }
        const errorTemp = e.target.value.split(',').map(Number)
        setErrorInput(errorTemp.map((n) => n - 1));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //errorOutput.push(tempData[0])
        //cleanOutput.push(tempData[0])
        errorInput.forEach(n => {
            errorOutput.push(tempData[n])
        });
        for(let i = 1; i < tempData.length; i ++){
            indexArray.push(i)
        }
        let cleanIndex = indexArray.filter((n) => !errorInput.includes(n));
        cleanIndex.forEach((n) => {
            cleanOutput.push(tempData[n])
        });
        //console.log(indexArray)
        //console.log(errorInput)
        //console.log(cleanIndex)
        //console.log(cleanOutput)

        const cleanDoc = xslx.utils.json_to_sheet(cleanOutput)
        const cleanBook = xslx.utils.book_new();
        const errorDoc = xslx.utils.json_to_sheet(errorOutput)
        const errorBook = xslx.utils.book_new();
        xslx.utils.book_append_sheet(cleanBook, cleanDoc, 'cleandoc')
        xslx.utils.book_append_sheet(errorBook, errorDoc, 'errordoc')
        xslx.write(cleanBook, {bookType: 'csv', type: 'buffer'})
        xslx.write(cleanBook, {bookType: 'csv', type: 'binary'})
        xslx.write(errorBook, {bookType: 'csv', type: 'buffer'})
        xslx.write(errorBook, {bookType: 'csv', type: 'binary'})
        xslx.writeFile(cleanBook, 'original_sin_errores.csv')
        xslx.writeFile(errorBook, 'solo_errores.csv'); 
        }

        const readExcel = (file) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const results = e.target.result;
                let wb = xslx.read(results, {type: 'binary'});
                const tab = wb.SheetNames[0];
                const doc = wb.Sheets[tab];
                const data = xslx.utils.sheet_to_json(doc, {header:0});
                setTempData(data);
            };
            reader.readAsBinaryString(file)
        }


    return(
        <div className="hero-container">
        <div className="container col-xl-10 col-xxl-8 px-4 py-5">
          <div className="row align-items-center g-lg-5 py-5">
            <div className="col-lg-7 text-lg-start m-0 p-0">
              <h3 className="display-4 fs-1 lh-1 mb-4 title">Bienvenido a TipClean</h3>
              <div>
                <ul className="p-0">
                  <li> 
                    <p className="step">1</p> 
                    <p className="mx-2 fs-6">Sube un documento para procesar</p>
                  </li>
                  <li> 
                    <p className="step">2</p> 
                    <p className="mx-2 fs-6">Ingresa el listado de errores como numeros, separados por una coma</p>
                  </li>
                  <li> 
                    <p className="step">3</p> 
                    <p className="mx-2 fs-6">Puedes copiar y pegar directamente la lista de errores</p>
                  </li>
                  <li> 
                    <p className="step">4</p> 
                    <p className="mx-2 fs-6">No dejes una coma al final de la lista de errores</p>
                  </li>
                  <li> 
                    <p className="step">5</p> 
                    <p className="mx-2 fs-6">Revisa las validaciones del formulario</p>
                  </li>
                  <li> 
                    <p className="step">6</p> 
                    <p className="mx-2 fs-6">Si todo esta ok haz click en descargar</p>
                  </li>
                  <li> 
                    <p className="step">7</p> 
                    <p className="mx-2 fs-6">Felicidades haz limpiado tu archivo</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-10 mx-auto col-lg-5">
              <form className="p-4 p-md-5 border rounded-3 bg-light was-validated" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input type="file" className="form-control" aria-label="file example" required onChange={handleFile}/>
                  <div className="invalid-feedback">Carga el archivo que deseas procesar</div>
                  <div className="valid-feedback">Archivo cargado correctamente</div>
                </div>
                <div className="form-floating mb-3">
                  <textarea id="text-area" className="form-control" required="required" onChange={handleChange}/>
                  <label>Ingresa la lista de errores</label>
                <div className="invalid-feedback pt-1">Ingresa una lista de errores</div>
                <div className="valid-feedback pt-1">Nota: No dejes una coma al final de la lista</div>
                </div>
                <button className={`w-100 btn btn-lg ${btnColor}`} type="submit">{btnText}</button>
                <hr className="my-4"/>
              </form>
            </div>
          </div>
        </div>
        </div>
    )
}