import './index.css'
import { HeroSection } from './View/Hero-Section';

function App() {
  return (
    <div className="App">
      <HeroSection/>
    </div>
  );
}

export default App;
